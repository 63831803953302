<template>
  <div>
    <div class="edit_box  mrb20">
      <div class="size16">
        筛选查询
      </div>
      <div class="mrt20">
        <el-form ref="form" :model="selectForm" label-width="80px" class="flex">
          <el-form-item label="页面名称">
            <el-input v-model="selectForm.name" placeholder="请输入页面名称" style="width: 200px;" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getList(Object.assign(selectForm,pageInfo))">查找</el-button>
            <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="edit()">添加</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="mrb20 bg-white pd20">
      <div class="mrb20">数据列表</div>
      <div>
        <el-table border :data="tableData" tooltip-effect="dark" style="width: 100%">
          <el-table-column label="序号" prop="id" align="center" width="120">
          </el-table-column>
          <el-table-column prop="name" label="页面名称" align="center">
          </el-table-column>
          <el-table-column prop="cover" label="图片" align="center">
            <template slot-scope="scope">
              <el-image :preview-src-list="[scope.row.cover]" style="width: 100px; height: 100px" :src="scope.row.cover" fit="fill"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="backgroup_image" label="背景图片" align="center">
            <template slot-scope="scope">
              <el-image :preview-src-list="[scope.row.backgroup_image]" style="width: 100px; height: 100px" :src="scope.row.backgroup_image" fit="fill"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间" align="center">
          </el-table-column>
          <el-table-column prop="updated_at" label="更新时间" align="center">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-edit" type="primary" @click="edit(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="handleDelete(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="mrt20 flex justify-end ">
          <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 50]" :page-size="pageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageInfo.count">
          </el-pagination>
        </div>

      </div>
    </div>

    <!-- 新增页面专区 -->
    <el-dialog title="页面专区" :visible.sync="originalVisible" width="80%" v-if="originalVisible" @close='addClose'>
      <el-form ref="originaForm" :model="originaForm" label-width="100px">
        <el-form-item label="页面名称" required>
          <el-input v-model="originaForm.name"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <file-pic :file='originaForm.cover' @input='originaForm.cover = $event'></file-pic>
        </el-form-item>
        <el-form-item label="背景图片">
          <file-pic :file='originaForm.backgroup_image' @input='originaForm.backgroup_image = $event'></file-pic>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click='addCommodity'>添加商品</el-button>
        </el-form-item>
        <el-form-item label="">
          <!-- 添加后的表格 -->
          <el-table border :data="addGoodsData" tooltip-effect="dark" style="width: 100%">
            <el-table-column label="序号" prop="id">
            </el-table-column>
            <el-table-column prop="status" label="上架状态">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
                </el-switch>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="address" label="列表展示">
          </el-table-column> -->
            <el-table-column prop="address" label="商品图片" width="120">
              <template slot-scope="scope">
                <el-image :preview-src-list="[scope.row.picture]" style="width: 100px; height: 100px" :src="scope.row.picture" fit="fill"></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称" width="120">
            </el-table-column>
            <!-- <el-table-column prop="address" label="商品品牌">
          </el-table-column>
          <el-table-column prop="address" label="商品品系列">
          </el-table-column> -->
            <el-table-column prop="price" label="价格">
            </el-table-column>
            <el-table-column prop="stock" label="总量">
            </el-table-column>
            <el-table-column prop="sales" label="销售">
            </el-table-column>
            <!-- <el-table-column prop="address" label="投放栏目">
          </el-table-column> -->
            <el-table-column prop="category_name" label="归属分类">
            </el-table-column>
            <el-table-column prop="created_at" label="创建时间" width="160">
            </el-table-column>
            <el-table-column prop="created_user_name" label="创建者" width="100">
            </el-table-column>
            <el-table-column prop="updated_at" label="更新时间" width="160">
            </el-table-column>
            <el-table-column prop="updated_user_name" label="操作者">
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="originalVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">{{ dialogTitle }}</el-button>
      </span>
    </el-dialog>

    <!-- 选择商品列表 -->
    <el-dialog title="添加商品" :visible.sync="goodsVisible" width="80%">
      <el-table ref="multipleTable" border row-key="id" :data="goodsData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" :reserve-selection="true">
        </el-table-column>
        <el-table-column label="序号" prop="id">
        </el-table-column>
        <el-table-column prop="status" label="上架状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
            </el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="address" label="列表展示">
          </el-table-column> -->
        <el-table-column prop="address" label="商品图片" width="120">
          <template slot-scope="scope">
            <el-image :preview-src-list="[scope.row.picture]" style="width: 100px; height: 100px" :src="scope.row.picture" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品名称" width="120">
        </el-table-column>
        <!-- <el-table-column prop="address" label="商品品牌">
          </el-table-column>
          <el-table-column prop="address" label="商品品系列">
          </el-table-column> -->
        <el-table-column prop="price" label="价格">
        </el-table-column>
        <el-table-column prop="stock" label="总量">
        </el-table-column>
        <el-table-column prop="sales" label="销售">
        </el-table-column>
        <!-- <el-table-column prop="address" label="投放栏目">
          </el-table-column> -->
        <el-table-column prop="category_name" label="归属分类">
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" width="160">
        </el-table-column>
        <el-table-column prop="created_user_name" label="创建者" width="100">
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" width="160">
        </el-table-column>
        <el-table-column prop="updated_user_name" label="操作者">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goodsVisible = false">取 消</el-button>
        <el-button type="primary" @click="goodsAdd()">确定添加</el-button>
      </span>
      <div class="mrt20 flex justify-end ">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="goodspageInfo.page" :page-sizes="[10, 20, 50]" :page-size="goodspageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="goodspageInfo.count">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import filePic from "@/components/common/filePic";
import { statusOption, statusDetail } from "@/utils/statusData";
export default {
  components: { filePic },
  props: {},
  data() {
    return {
      statusOption,
      statusDetail,
      originalVisible: false,
      goodsVisible: false,
      pageInfo: {
        limit: 10,
        page: 1,
        count: 0,
        pageNum: 0,
      },
      goodspageInfo: {
        limit: 10,
        page: 1,
        count: 0,
        pageNum: 0,
      },
      selectForm: {
        name: "",
        status: "",
      },
      // 新增
      originaForm: {
        cover: "",
        goods_ids: [],
        id: "",
        name: "",
        goods_ids: "",
        backgroup_image: "",
      },
      originalOption: [],
      tableData: [],
      goodsData: [],
      addGoodsData: [],
      evaluatePictureList: "",
      ifMaterialEdit: 0, // 0表示编辑，1表示新增
      dialogTitle: "",
      multipleSelection: [],
    };
  },

  created() {
    this.getList(this.pageInfo);
    this.originalList();
    this.goodsList(this.goodspageInfo);
  },
  mounted() {},
  computed: {},

  methods: {
    //
    handleSelectionChange(val) {
      //   this.multipleSelection = [...val, ...this.addGoodsData];

      this.multipleSelection = val;
    },
    setSelectRows(selection, row) {
      //将当前勾选的表格状态清除
      this.$refs.multipleTable.clearSelection();
      // 当表格数据都没有被勾选的时候 就返回
      if (selection.length == 0) return;
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    // 设置表格勾选
    setColumn() {
      this.$nextTick(() => {
        // this.$refs.multipleTable.clearSelection();
        let data = this.addGoodsData.map((i) => i.id);
        this.goodsData.forEach((i) => {
          if (data.includes(i.id)) {
            this.$refs.multipleTable.toggleRowSelection(i, true);
          }
        });
      });
    },
    // 添加商品弹窗
    addCommodity() {
      this.goodsVisible = true;
      // console.log("现有数据", this.addGoodsData);

      this.setColumn();
    },
    // 确定添加商品
    goodsAdd() {
      // this.
      this.originaForm.goods_ids = this.multipleSelection.map(
        (item) => item.id
      );
      this.goodsVisible = false;
      this.getAddGoods(this.originaForm.goods_ids);
    },
    async goodsList(goodspageInfo) {
      const { data } = await this.$Api.goodList(goodspageInfo);
      this.goodsData = data.list;
      // console.log("  this.goodsData =", this.goodsData);

      this.goodspageInfo.limit = data.limit;
      this.goodspageInfo.page = data.page;
      this.goodspageInfo.count = data.count;
      this.goodspageInfo.pageNum = data.pageNum;
      this.setColumn();

      // Object.assign(this.pageInfo, data);
    },
    async getAddGoods(row) {
      // console.log("第一个弹窗id", row);
      if (row !== undefined && row.length > 0) {
        const { data } = await this.$Api.goodList({ goods_ids: row });
        this.addGoodsData = data.list;
      }

      // console.log("获取编辑的表格", data);
    },

    async originalList() {
      const { data } = await this.$Api.originalList();
      this.originalOption = data.list.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      // Object.assign(this.pageInfo, data);
    },
    // 新增编辑
    edit(row) {
      if (row) {
        this.ifMaterialEdit = 0;
        this.originaForm.id = row.id;
        this.originaForm.goods_ids = row.goods_ids;
        this.getAddGoods(this.originaForm.goods_ids); //商品表格
        this.getList(this.pageInfo);
        this.originalVisible = true;
        this.dialogTitle = "编辑";
        this.originaForm = { ...row };
      } else {
        // console.log("新增咯");
        this.ifMaterialEdit = 1;
        this.originalVisible = true;
        this.dialogTitle = "新增";
        // this.getAddGoods(this.originaForm.goods_ids); //商品表格
        this.getList(this.pageInfo);
        // this.$set(this.originaForm, goods_ids, []);
        this.addGoodsData = [];
        // console.log("重新赋值", this.addGoodsData);
        this.originaForm = {
          cover: "",
          goods_ids: [],
          id: "",
          name: "",
          goods_ids: "",
          backgroup_image: "",
        };
        // console.log("被清了", this.originaForm);
      }
    },
    async onSubmit() {
      // 新增接口对接
      if (this.ifMaterialEdit == 1) {
        // console.log("新增管理", this.originaForm);
        const data = await this.$Api.animePageEdit(this.originaForm);
        if (data.code !== 200) {
          this.$message.error(data.msg);
          return;
        }
        this.$message.success("新增成功");
        this.originalVisible = false;
        this.getList(this.pageInfo);
        this.addGoodsData = [];
      } else if (this.ifMaterialEdit == 0) {
        // 编辑接口对接
        const data = await this.$Api.animePageEdit(this.originaForm);
        this.$message.success("编辑成功");
        this.originalVisible = false;
        this.getList(this.pageInfo);
      }
    },
    async getList(pageInfo) {
      const { data } = await this.$Api.animePage(pageInfo);
      this.tableData = data.list;
      // console.log("专区列表列表", data);
      this.pageInfo.limit = data.limit;
      this.pageInfo.page = data.page;
      this.pageInfo.count = data.count;
      this.pageInfo.pageNum = data.pageNum;
      // Object.assign(this.pageInfo, data);
      // console.log(data, "成功的原著列表");
    },

    handleSizeChange1(val) {
      // console.log("val", val);
      this.pageInfo.limit = val;
      this.pageInfo.page = 1;
      this.getList(this.pageInfo);
    },
    handleCurrentChange1(val) {
      // console.log("val", val);
      this.pageInfo.page = val;
      this.getList(this.pageInfo);
    },
    handleSizeChange(val) {
      this.goodspageInfo.limit = val;
      this.goodspageInfo.page = 1;
      this.goodsList(this.goodspageInfo);
    },
    handleCurrentChange(val) {
      this.goodspageInfo.page = val;
      this.goodsList(this.goodspageInfo);
    },
    // 批量删除
    // batchdelete() {
    //   this.$confirm("确定要删除该商品信息吗?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       console.log("点击删除");
    //       return;
    //       this.$message({
    //         type: "success",
    //         message: "删除成功!",
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    // 关闭添加
    addClose() {
      this.originaForm = {
        goods_ids: [],
        id: "",
        name: "",
        sort: "",
        status: "",
        backgroup_image: "",
      };
      // console.log("被清了", this.originaForm);
    },
    // 单个删除
    handleDelete(row) {
      this.$confirm("确定要删除该商品信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$Api.animePageDel({ ids: [row] });
          // console.log("删除成功", data);
          if (data.code != 200) {
            return this.$message({
              message: data.msg,
              type: "error",
            });
          }
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.getList(this.pageInfo);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: data.msg,
          });
        });
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-tooltip__popper.is-dark {
  width: 700px !important;
}
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
</style>